import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Styles/custom.scss';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import { Provider} from "react-redux";

import Cookies from 'js-cookie';

import reportWebVitals from './reportWebVitals';


import App from './Pages/App';
import Home from './Pages/Home';
// import Contact from "./Pages/Contact/Contact";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Projects from "./Pages/Projects";


import store from './AppReduxState/appStore';

let appViewMode = Cookies.get('app-view-mode');
console.log('appViewMode: ', appViewMode);
if(appViewMode) {
    store.dispatch({type: 'SET_APP_VIEW_MODE', data: appViewMode});
}


const router = createBrowserRouter([
    // {
    //     path: "/",
    //     element: <App/>,
    // },
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/contact",
        element: <Contact/>,
    },
    {
        path: "/about",
        element: <About/>,
    },
    {
        path: "/projects",
        element: <Projects/>,
    },
]);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <RouterProvider router={router} />
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import * as _ from 'lodash';
import Cx from 'classnames';
import logo from '../../logo.svg';
import './Contact.scss';

import { IPageProps } from '../../Types/componentProps-types';

import Navbar from "../../CommonComponents/Navbar";



function Contact(props:IPageProps) {
    // console.log('Contact() -props-> ', props);
    let appStateCount = _.get(props, 'state.app.count', null);
    let appStateViewMode = _.get(props, 'state.app.viewMode', null);

    let contactPageContainerClasses = Cx({
        'page-container': true,
        'dark-mode': appStateViewMode == 'dark',
    });

    return (
        <div className="App">

            <Navbar activePage={"contact"} />

            <div className={contactPageContainerClasses}>
                <h1>
                    Contact
                </h1>
                <img src={logo} className="App-logo" alt="logo" />

                <h6>
                    Count: {appStateCount}
                </h6>

                <button className="btn btn-outline-primary" onClick={() => props.add()}>
                    Add
                </button>

                <button className="btn btn-outline-primary" onClick={() => props.sub()}>
                    Sub
                </button>


                <hr />

                <h6>
                    App View Mode: {appStateViewMode}
                </h6>

                <button className="btn btn-outline-primary" onClick={() => props.setViewModeLight()}>
                    Light Mode
                </button>

                <button className="btn btn-outline-primary" onClick={() => props.setViewModeDark()}>
                    Dark Mode
                </button>

            </div>
        </div>
    );

}

export default Contact;

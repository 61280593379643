import React from 'react';
import Cx from 'classnames';
import './Navbar.scss';

import { Link } from 'react-router-dom';

interface INavbarProps {
    activePage ?: string;
    state?: any;
    setViewModeLight?: () => void;
    setViewModeDark?: () => void;
}

function Navbar(props:INavbarProps) {

    console.log('Navbar() -props-> ', props);

    let viewMode = props.state.app.viewMode;

    let createNavLinkClasses = (pageName:string) => Cx({
       'nav-link': true,
       'nav-link-with-icon': false,
       'active': props.activePage == pageName,
    });

    // let isDarkNav = false;
    let isDarkNav = !!(viewMode == 'dark');

    let navClasses = Cx({
        'navbar': true,
        'app-navbar': true,
        'bg-dark': isDarkNav,
        'bg-light': !isDarkNav,
        'navbar-dark': isDarkNav,
        'navbar-expand-lg': true,
        // 'bg-body-tertiary': false,
        'fixed-top': true,
    });

    return (
        <nav
            className={navClasses}
            data-bs-theme={"dark"}>

            <div className="container container-fluid___">
                <Link className="navbar-brand" to="/">
                    Greg Wilson
                </Link>
                {/*<a className="navbar-brand" href="/">*/}
                {/*    Greg Wilson*/}
                {/*</a>*/}
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">

                        <li className="nav-item">
                            <Link
                                className={createNavLinkClasses("about")}
                                aria-current="page"
                                to="/About">

                                <i className="bi bi-geo-alt"></i>

                                About

                            </Link>
                        </li>


                        <li className="nav-item">
                            <Link
                                className={createNavLinkClasses("projects")}
                                to="/Projects">

                                <i className="bi bi-braces-asterisk"></i>

                                Projects

                            </Link>
                        </li>



                        <li className="nav-item">
                            <Link
                                className={createNavLinkClasses("contact")}
                                to="/Contact">

                                <i className="bi bi-person-lines-fill"></i>


                                Contact

                            </Link>
                        </li>


{
    /***



                        <li className="nav-item dropdown">
                            <a
                                className={"nav-link dropdown-toggle"}
                                href="/Projects"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">

                                More...

                            </a>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Action
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Another action
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Something else here
                                    </a>
                                </li>
                            </ul>
                        </li>

                        ***/
                        }

                    </ul>
                </div>
            </div>
        </nav>
    );

}

export default Navbar;

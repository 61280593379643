import { Reducer } from '@reduxjs/toolkit';
import * as AppActions from './appActions';

import Cookies from 'js-cookie';

export interface IAppState {
    count: number;
    viewMode: string;
}


const initialState :IAppState = {
    count: 0,
    // viewMode: 'dark'
    viewMode: 'light'
};


const reducer: Reducer = (
    state: IAppState = initialState,
    action: any
): IAppState => {
    switch (action.type) {
        case AppActions.ADD_TEST:
            const newCount = state.count + 1;
            return {
                ...state,
                count: newCount,
            }
        case AppActions.SUB_TEST:
            const newCountSub = state.count - 1;
            return {
                ...state,
                count: newCountSub,
            }
        case AppActions.SET_APP_VIEW_MODE:
            let newViewMode = action.data;
            Cookies.set('app-view-mode', newViewMode);
            return {
                ...state,
                viewMode: newViewMode,
            }
    }
    return state;
}

export default reducer;

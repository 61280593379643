import React from 'react';
import * as _ from "lodash";
import Cx from "classnames";
import logo from '../../logo.svg';

import './Home.scss';

import { IPageProps } from '../../Types/componentProps-types';

import Navbar from "../../CommonComponents/Navbar";
import PageNav from "../../CommonComponents/PageNav";

import HomePageNav from "./homePageNav";


function Home(props?:IPageProps) {

    let appStateCount = _.get(props, 'state.app.count', null);
    let appStateViewMode = _.get(props, 'state.app.viewMode', null);

    let appPageContainerClasses = Cx({
        'page-container': true,
        'dark-mode': appStateViewMode == 'dark',
    });

    return (
        <div className="App">

            {/*<Navbar activePage={"home"} />*/}

            <div className={appPageContainerClasses}>

                {/*<div className={'page-content'}>*/}
                {/*    <h1>*/}
                {/*        Greg Wilson*/}
                {/*    </h1>*/}
                {/*    <br/>*/}
                {/*    <h2>*/}
                {/*        Software Development*/}
                {/*    </h2>*/}
                {/*</div>*/}

                <div className={'page-content'}>

                    <div className={'row'}>
                        <div className='col'>
                            {/*<PageNav activePage={"home"} />*/}
                            <HomePageNav activePage={"home"}/>
                        </div>
                    </div>



                    <div className={'row mt-5'}>
                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <h1 className={'home-page-heading'}>
                                Hi! I'm Greg
                            </h1>

                        </div>
                        <div className='col-8'>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
}

export default Home;

import React from 'react';
import * as _ from "lodash";
import Cx from "classnames";
import * as THREE from 'three'
import { Suspense, } from 'react';
import { useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, OrbitControls, } from '@react-three/drei';

import logo from '../../logo.svg';
import './Projects.scss';

import { IPageProps } from '../../Types/componentProps-types';


import Navbar from "../../CommonComponents/Navbar";


// let glbUrl = "https://storage.googleapis.com/search-ar-edu/periodic-table/element_092_uranium/element_092_uranium.glb";
// let glbUrl = "./PorscheSpider.glb";
// let glbUrl = "./Giulettina.glb";
// let glbUrl = "https://solarsystem.nasa.gov/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaTBSIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7c1183d35fdc9e4b5143c8601376552b89b5d99f/Earth_1_12756.glb?disposition=attachment";
// let glbUrl = "./Earth.glb";
// let glbUrl = "./Cassini.glb";
let glbUrl = "./Delta2.glb";

function Model(props:any) {
    let { scene } = useGLTF(glbUrl);
    return <primitive object={scene} />;
}


function Projects(props:IPageProps) {

    let appStateCount = _.get(props, 'state.app.count', null);
    let appStateViewMode = _.get(props, 'state.app.viewMode', null);

    let projectsPageContainerClasses = Cx({
        'page-container': true,
        'dark-mode': appStateViewMode == 'dark',
    });

    let pxRatio :any = [1,2];
    let pxRatioProps :any = {pixelratio: pxRatio};


    return (
        <div className="App">

            <Navbar activePage={"projects"} />

            <div className={projectsPageContainerClasses}>
                {/*<img src={logo} className="App-logo" alt="logo" />*/}
                <div className="projects-page-main-content">


                    <h1>
                        Projects
                    </h1>
                    {/*<hr />*/}
                    <br/>
                    <div className={'three-js-container'}>
                        <Canvas {...pxRatioProps}>
                            {/*<Canvas camera={{ position: [-10, 15, 15], fov: 50 }} {...pxRatioProps}>*/}
                            {/*<Canvas camera={{ position: [-10, 15, 15], fov: 50 }}>*/}
                            <ambientLight color={"#ffffff"} intensity={0.5} />
                            <Suspense fallback={null}>
                                <Model />
                            </Suspense>
                            <OrbitControls />
                        </Canvas>

                    </div>

                </div>

            </div>
        </div>
    );

}

export default Projects;

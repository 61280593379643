import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import About from './About'

function mapDispatchToProps(dispatch: Dispatch<any>) {
    // return dispatch({ type: 'ALERT_USER' });
    return {
        add: () => dispatch({type: 'ADD_TEST'}),
        sub: () => dispatch({type: 'SUB_TEST'}),
        setViewModeLight: () => dispatch({type: 'SET_APP_VIEW_MODE', data: 'light'}),
        setViewModeDark: () => dispatch({type: 'SET_APP_VIEW_MODE', data: 'dark'}),
    }
}

function mapStateToProps(state: any) {
    return {
        // label: 'my test label',
        state: state,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(About);


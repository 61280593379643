import React from 'react';
import * as _ from "lodash";
import Cx from 'classnames';
import logo from '../../logo.svg';
import './About.scss';

import { IPageProps } from '../../Types/componentProps-types';


import Navbar from "../../CommonComponents/Navbar";



function About(props:IPageProps) {

    let appStateCount = _.get(props, 'state.app.count', null);
    let appStateViewMode = _.get(props, 'state.app.viewMode', null);

    let aboutPageContainerClasses = Cx({
        'page-container': true,
        'dark-mode': appStateViewMode == 'dark',
    });


    return (
        <div className="App_">

            <Navbar activePage={"about"} />

            <div className={aboutPageContainerClasses}>


            {/*<header className="App-header_">*/}
                {/*<img src={logo} className="App-logo" alt="logo" />*/}
                <h1>
                    About
                </h1>

                <hr />

                <div className="skills-container">
                    <h1>
                        Skills
                    </h1>

                    <i className="bi bi-activity"></i>


                    <h3 className="skill-heading">

                        Process
                    </h3>
                    <p className="skill-content">
                        Software Development, Agile, SCRUM, Jira, Asana,
                        <br/>
                        Launch Darkly
                    </p>



                    <h3 className="skill-heading">
                        Languages
                    </h3>
                    <p className="skill-content">
                        JavaScript&nbsp;
                        <span className="sub-content">
                            (Vanilla, TypeScript, NodeJS)
                        </span>
                        , C/C++, Python
                        <br/>
                        C# / .NET
                        <br/>
                        Objective C
                        <br/>
                        Go
                    </p>

                    <h3 className="skill-heading">
                        Backend
                    </h3>
                    <p className="skill-content">
                        Databases (SQL + noSQL), Caches, Message Queues (RabbitMQ, AWS SQS), Servers
                    </p>


                    <h3 className="skill-heading">
                        Frontend
                    </h3>
                    <p className="skill-content">
                        React, Redux, AJAX API, SCSS, HTML5, Canvas, 3D
                    </p>


                    {/*<h6>*/}
                    {/*    Backend: Databases (SQL + noSQL), Caches, Message Queues, Servers*/}
                    {/*</h6>*/}


                    <h3 className="skill-heading">
                        API
                    </h3>
                    <p className="skill-content">
                        REST API, Express, Swagger, HTTP, Arduino-Integration, Apple HealthKit, Phillips Hue
                    </p>

                    <h3 className="skill-heading">
                        Web Service Providers
                    </h3>
                    <p className="skill-content">
                        AWS, Linode, Azure, GSuite, Analytics
                    </p>

                    <h3 className="skill-heading">
                        Continuous Integration
                    </h3>
                    <p className="skill-content">
                        GIT, Jenkins, Github Webhooks, etc.
                    </p>



                    <h3 className="skill-heading">
                        Mobile Development
                    </h3>
                    <p className="skill-content">
                        REACT NATIVE, advanced web app
                    </p>


                    <h3 className="skill-heading">
                        VMs
                    </h3>
                    <p className="skill-content">
                        vmware, docker, etc.
                    </p>


                    <h3 className="skill-heading">
                        Security
                    </h3>
                    <p className="skill-content">
                        OAuth, Bcrypt, SSL, etc.
                    </p>


                    <h3 className="skill-heading">
                        DevOps
                    </h3>
                    <p className="skill-content">
                        Apache, Linux, SSL, SSH, DNS, instance management / cloud instances
                    </p>


                    <h3 className="skill-heading">
                        Accessibility
                    </h3>
                    <p className="skill-content">
                        i18n, web accessibility (screen reader, contrast/font size, aria)
                    </p>


                    <hr />


                    <h3 className="skill-heading">
                        Arduino / Circuits / Sensors / INTEGRATION
                    </h3>
                    <h3 className="skill-heading">
                        C / C++ - Arduino
                    </h3>
                    <h3 className="skill-heading">
                        Python - Raspberry Pi
                    </h3>
                    <h3 className="skill-heading">
                        3D Modeling & PRINTING
                    </h3>
                    <h3 className="skill-heading">
                        Integration
                    </h3>
                </div>

            {/*</header>*/}

        </div>
        </div>
    );
}

export default About;
